import React from 'react';
import Report from '../../Reports/Report';
import CutSheetFooter from '../CutSheetFooter';
import {CutSheetTemplate} from '../CutSheetTemplate';

interface Props {
  data: any;
}

//interface State {}

class SSAssembly extends CutSheetTemplate {
  report: Report;

  constructor(props: Props) {
    super(props);
    this.state = {};
    this.report = new Report();
  }

  render() {
    this.report = new Report();
    this.report.addHeader(
      2,
      <React.Fragment>
        <div className="d-flex justify-content-between mb-2mm mt-20mm">
          {/* Meta data */}
          <table className="header">
            <tr>
              <td className="label">order code</td>
              <td className="text">
                {this.props.data.order_id}
                {this.props.data.type === 'Repair' ? ' REPAIR' : ''}
              </td>
              <td className="label">dealer</td>
              <td className="text">{this.props.data.dealership_name}</td>
              <td className="label">bay</td>
              <td className="text">{this.props.data?.production_bay}</td>
              <td className="label">ss std v2</td>
              <td className="text">
                <b>ASSEMBLY</b>
              </td>
            </tr>
            <tr>
              <td className="label">reference</td>
              <td className="text" colSpan={3}>
                {this.props.data.reference}
              </td>
              <td className="label">shipping to</td>
              <td className="text">{this.props.data.ship_to}</td>
              <td className="label">ecd</td>
              <td className="text">
                <b>{this.props.data.ecd}</b>
              </td>
            </tr>
          </table>
        </div>
      </React.Fragment>
    );

    {
      this.props.data.assembly.items.map((item: any) => {
        let currentLineCount = 6;
        let cover;
        let coverBrush;
        let arms;
        let weightBarExtras;
        let note;
        let repair1;
        let repair2;

        if (item.content.repair_responsibility) {
          repair1 = (
            <tr>
              <td>{item.order_item_index}</td>
              <td className="label">rework</td>
              <td className="text">{item.content.repair_whole_item ? 'YES' : 'NO'}</td>
              <td className="label">responsibility</td>
              <td className="text" colSpan={4}>
                {this.capitaliseFirst(item.content.repair_respo)}
              </td>
            </tr>
          );
          repair2 = (
            <tr>
              <td />
              <td className="label">repair detail</td>
              <td className="text" colSpan={5}>
                {item.content.repair_details}
              </td>
              <td className="sign-off">check</td>
            </tr>
          );
          currentLineCount += 2;
        } else {
          repair1 = repair2 = '';
        }

        if (item.content.has_headbox || item.content.box_type) {
          cover = (
            <tr>
              <td />
              <td className="label">box/open</td>
              <td className="text">{this.capitaliseFirst(item.content.box_type)}</td>
              <td className="label">colour</td>
              <td className="text">{this.capitaliseFirst(item.content.box_colour)}</td>
              <td className="label">length</td>
              <td className="number">{item.content.box_length}</td>
              <td className="sign-off">done</td>
            </tr>
          );
          currentLineCount++;
        } else {
          cover = (
            <tr>
              <td />
              <td className="label">box/open</td>
              <td className="text" colSpan={3}>
                Open
              </td>
              <td className="label">bracket c</td>
              <td className="text">{this.capitaliseFirst(item.content.box_colour)}</td>
              <td className="sign-off">done</td>
            </tr>
          );
          currentLineCount++;
        }
        if (item.content.box_brush) {
          coverBrush = (
            <tr>
              <td/>
              <td className="label">brush</td>
              <td className="text" colSpan={3}>{this.capitaliseFirst(item.content.box_brush)}</td>
              <td className="label">length</td>
              <td className="text">{item.content.box_brush_length}</td>
              <td className="sign-off">done</td>
            </tr>
          );
        }

        if (item.content.arm_length) {
          arms = (
            <React.Fragment>
              <tr>
                <td/>
                <td className="label">arm</td>
                <td className="text">Left</td>
                <td className="label">colour</td>
                <td className="text">{this.capitaliseFirst(item.content.arm_colour)}</td>
                <td className="label">length</td>
                <td className="number">{item.content.arm_length}</td>
                <td className="sign-off">cut</td>
              </tr>
              <tr>
                <td />
                <td className="label">arm</td>
                <td className="text">Right</td>
                <td className="label">colour</td>
                <td className="text">{this.capitaliseFirst(item.content.arm_colour)}</td>
                <td className="label">length</td>
                <td className="number">{item.content.arm_length}</td>
                <td className="sign-off">cut</td>
              </tr>
            </React.Fragment>
          );
          currentLineCount += 2;
        } else {
          arms = '';
        }

        const weightBar = (
          <tr>
            <td />
            <td className="label">weight bar</td>
            <td className="text">{this.capitaliseFirst(item.content.wb_type)}</td>
            <td className="label">colour</td>
            <td className="text">{this.capitaliseFirst(item.content.wb_colour)}</td>
            <td className="label">caps</td>
            <td className="text">{this.capitaliseFirst(item.content.caps)}</td>
            <td className="sign-off">done</td>
          </tr>
        );

        if (item.content.locks || item.content.skirt || item.content.steel_weight) {
          weightBarExtras = (
            <tr>
              <td />
              <td className="label">skirt</td>
              <td className="text">{this.capitaliseFirst(item.content.skirt)}</td>
              <td className="label">weight</td>
              <td className="text">{item.content.steel_weight}</td>
              <td className="label">lock</td>
              <td className="text">{this.capitaliseFirst(item.content.lock)}</td>
              <td className="sign-off">done</td>
            </tr>
          );
          currentLineCount += 1;
        } else {
          weightBarExtras = '';
        }

        if (item.content.notes) {
          note = (
            <tr>
              <td />
              <td className="label">note</td>
              <td className="text" colSpan={5}>
                {`${item.content.notes}`.replace(/\r?\n|\r/g, ' ')}
              </td>
            </tr>
          );
          currentLineCount += Math.ceil(`${item.content.notes}`.length / 90);
        } else {
          note = '';
        }

        this.report.addItem(
          item,
          currentLineCount,
          <React.Fragment>
            <table className="mt-10 odd-zebra three-cols content">
              {repair1}
              {repair2}
              <tr>
                <td>{item.order_item_index}</td>
                <td className="label">product</td>
                <td className="text">{item.content.product}</td>
                <td className="label">b2b width</td>
                <td className="number">{item.content.width}</td>
                <td className="label">drop</td>
                <td className="number">{item.content.drop}</td>
                <td className="sign-off">check</td>
              </tr>
              <tr>
                <td />
                <td className="label">fabric</td>
                <td className="text">{this.capitaliseFabric(item.content.fabric)}</td>
                <td className="label">colour</td>
                <td className="text" colSpan={3}>
                  {this.capitaliseFirst(item.content.fab_colour)}
                </td>
                <td className="sign-off">check</td>
              </tr>
              <tr>
                <td />
                <td className="label">tube</td>
                <td className="text">{this.capitaliseFirst(item.content.tube_type)}</td>
                <td className="label">ctrl side</td>
                <td className="text">{item.content.control_side.toUpperCase()}</td>
                <td className="label">mount</td>
                <td>{this.capitaliseFirst(item.content?.mount)}</td>
                <td className="sign-off">done</td>
              </tr>
              <tr>
                <td />
                <td className="label">ctrl type</td>
                <td className="text">{this.capitaliseFirst(item.content.control_type)}</td>
                <td className="label">insert left</td>
                <td className="text">{this.capitaliseFirst(item.content.insert_l)}</td>
                <td className="label">insert right</td>
                <td className="text">{this.capitaliseFirst(item.content.insert_r)}</td>
                <td className="sign-off">done</td>
              </tr>
              {cover}
              {coverBrush}
              {arms}
              {weightBar}
              {weightBarExtras}
              {note}
            </table>
          </React.Fragment>
        );
      });
    }

    {
      /* CHECK SUM */
    }
    this.report.addItem(
      this.props.data,
      1,
      <React.Fragment>
        <table className="summary">
          <td className="label">total</td>
          <td className="text">{this.props.data.assembly.items.length}</td>
        </table>
      </React.Fragment>
    );

    {
      /* FOOTER */
    }
    this.report.addFooter(1, new CutSheetFooter());

    return (
      <div className="cut-sheet">
        <div className="colorvue-standard-saw-v1">{this.report.getReport()}</div>
      </div>
    );
  }
}

export default SSAssembly;
